/**
 * Logged-IN home page
 */

import type { EpisodeStatus } from "@prisma/client";
import * as Lucide from "lucide-react";
import { Form, Link, useLoaderData, type ActionFunctionArgs, type LoaderFunctionArgs } from "react-router";
import { db } from "./.server/db";
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import { EpisodeContent } from "./episode/episode";
import { getLatestCompletedEpisode, getNextEpisode } from "./episode/get.server";
import { episodeShortName, nextEpisodePublishDay, PREVIEW_EPISODE_KEY } from "./episode/props";
import { publishPendingEpisodes } from "./episode/publish.server";
import { resetPreviewEpisode } from "./episode/schedule.server";
import { formatTimeOfDay } from "./user/time";
import { requireAppToken } from "./user/token.server";
import { cn } from "./utils";
import { useUser } from "./with-user";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { userId } = await requireAppToken(request);
  const nextEpisode = await getNextEpisode(userId);
  const latestEpisode = await db.episode.findFirst({
    where: {
      userId,
      status: { not: "queued" },
    },
    orderBy: { scheduledAt: "desc" },
  });
  const priorEpisodes = await db.episode.findMany({
    where: {
      userId,
      status: { not: "queued" },
      AND: [{ episodeKey: { not: latestEpisode?.episodeKey } }, { episodeKey: { not: PREVIEW_EPISODE_KEY } }],
    },
    orderBy: { scheduledAt: "desc" },
    select: { episodeKey: true, scheduledAt: true, status: true },
  });
  return { nextEpisode, latestEpisode, priorEpisodes };
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const { userId } = await requireAppToken(request);
  await resetPreviewEpisode(userId);
  await publishPendingEpisodes();
};

enum TimelineIcon {
  Next = 0,
  Ready = 1,
  Processing = 2,
  Error = 3,
  Past = 4,
  Generate = 5,
}

function TimelineEntry({
  children,
  className,
  icon,
}: { children: React.ReactNode; className?: string; icon: TimelineIcon }) {
  return (
    <div className={cn("flex items-center z-10 relative bg-gray-100 rounded-md pr-1 ", className)}>
      {icon === TimelineIcon.Next && <Lucide.CircleDashed className="size-5 m-1 mr-2 text-gray-400" />}
      {icon === TimelineIcon.Ready && <Lucide.CircleCheck className="size-5 m-1 mr-2 text-green-500" />}
      {icon === TimelineIcon.Processing && <Lucide.CircleEllipsis className="size-5 m-1 mr-2 text-yellow-500" />}
      {icon === TimelineIcon.Error && <Lucide.CircleX className="size-5 m-1 mr-2 text-red-500" />}
      {icon === TimelineIcon.Past && <Lucide.CircleCheck className="size-5 m-1 mr-2 text-gray-400" />}
      {icon === TimelineIcon.Generate && <Lucide.CirclePlus className="size-5 m-1 mr-2 text-blue-500" />}
      <span className="flex-grow ">{children}</span>
    </div>
  );
}

function NextEpisode() {
  const { nextEpisode } = useLoaderData<typeof loader>();
  const user = useUser();

  return (
    <div className="pt-4 flex items-center gap-1">
      <TimelineEntry icon={TimelineIcon.Next} className="text-gray-500">
        {nextEpisode ? (
          nextEpisode.catchup ? (
            <span>Episode creation is paused until {nextEpisodePublishDay(nextEpisode, user, true)}</span>
          ) : (
            <span>
              Next episode will be created {nextEpisodePublishDay(nextEpisode, user)} at {formatTimeOfDay(user)}
            </span>
          )
        ) : (
          <span>Episode creation is paused</span>
        )}
      </TimelineEntry>
      <Button variant="outline2" size="mini" className="text-muted-foreground" asChild>
        <Link to="/settings/episodes">Change</Link>
      </Button>
    </div>
  );
}

function GeneratePreview() {
  return (
    <Form method="post" className="pt-8 flex">
      <button type="submit" className="text-blue-500 rounded-md group">
        <TimelineEntry icon={TimelineIcon.Generate} className="pb-0  group-hover:bg-white">
          Generate preview
        </TimelineEntry>
      </button>
    </Form>
  );
}

function EpisodeName({
  episode,
  latest,
}: { episode: { episodeKey: string; scheduledAt: Date; status?: EpisodeStatus }; latest?: boolean }) {
  const user = useUser();
  const status = episode.status;
  const icon =
    status === "ok"
      ? latest
        ? TimelineIcon.Ready
        : TimelineIcon.Past
      : status === "error"
        ? TimelineIcon.Error
        : TimelineIcon.Processing;
  return (
    <h2 className="pt-8">
      <Link to={`/${episode.episodeKey}`} className="group inline-block">
        <TimelineEntry icon={icon} className="pb-0 group-hover:bg-white rounded-md font-semibold">
          {episodeShortName(episode, user)}
        </TimelineEntry>
      </Link>
    </h2>
  );
}

function LatestEpisode() {
  const { latestEpisode } = useLoaderData<typeof loader>();
  if (!latestEpisode) {
    return <GeneratePreview />;
  }
  return (
    <>
      <EpisodeName episode={latestEpisode} latest={true} />
      <Card className="mt-2 ml-7">
        <CardContent className="py-6">
          <EpisodeContent episode={latestEpisode} />
        </CardContent>
      </Card>
    </>
  );
}

function PriorEpisodes() {
  const { priorEpisodes } = useLoaderData<typeof loader>();
  return (
    <>
      {priorEpisodes.map((episode) => (
        <EpisodeName key={episode.episodeKey} episode={episode} />
      ))}
    </>
  );
}

export default function Index() {
  const { priorEpisodes } = useLoaderData<typeof loader>();
  const hasPriorEpisodes = priorEpisodes.length > 0;
  return (
    <div className="content-w relative mb-8">
      <div
        className="border-l-2 border-gray-300 pl-4 absolute -top-[14px] left-[13px]"
        style={{ height: hasPriorEpisodes ? "calc(100%)" : "100px" }}
      />
      <NextEpisode />
      <LatestEpisode />
      <PriorEpisodes />
    </div>
  );
}
